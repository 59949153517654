var isMobile = mobileAndTabletcheck();
var scrollTop = 0,
	mobileMenu = $(".mobile-menu"),
	wrapper = $(".wrapper");

function mapInit(){
	var map;
	var geocoder = new google.maps.Geocoder();
	var center;
	map = new google.maps.Map(document.getElementById('googlemap'), {
		center: {lat: 22.365, lng: 114.133},
		zoom: 18,
		styles: [

		]
	})
	geocoder.geocode({
		'address': "葵涌葵昌路8號萬泰中心"
	}, function(results, status) {
		if (status == google.maps.GeocoderStatus.OK) {
			var pos = new google.maps.LatLng(results[0].geometry.location.lat(), results[0].geometry.location.lng())
			var image;
			var marker = new google.maps.Marker({
				position: pos,
				map: map,
				icon: image!=null?image:null
			});
			map.setCenter(pos);
		}
	});
}

$(function(){
	$(".subscribe-container .submit-button").click(function(){
		if(!$(this).hasClass('disabled')){
			$(this).addClass('disabled')
			$('#subscribeForm').submit();
		}
	})
	$('#subscribeForm').on('ajaxSuccess', function() {
	    $(".subscribe-success").show();
	    $("input", this).prop('readonly', true);
	}).on('ajaxFail', function() {
	    $(".subscribe-container .submit-button").removeClass('disabled');
	})

	$('[data-fancybox="gallery"]').fancybox({
		// smallBtn: false,
		buttons: [
			// "zoom",
			//"share",
			// "slideShow",
			//"fullScreen",
			//"download",
			// "thumbs",
			"close"
		],
	});

	$(".mobile-trigger").click(function(e){
		e.preventDefault();
		if(!$(this).children().hasClass('is-active')){
			$(this).children().addClass('is-active');
			$("body").addClass('openMenu');
			$("html").addClass('noscrolling')
		}else{
			$(this).children().removeClass('is-active');
			$("body").removeClass('openMenu');
			$("html").removeClass('noscrolling')
		}
	})

	$(".mobile-standard-select-nav .trigger").click(function(e){
		e.preventDefault();
		var container = $(this).closest(".mobile-standard-select-nav");
		if(container.hasClass("dropdown")){
			container.removeClass("dropdown");
		}else{
			container.addClass("dropdown");
		}
	})

	$(".mobile-standard-select-nav .drop a").click(function(e){
		var container = $(this).closest(".mobile-standard-select-nav");
		var button = $(this);
		container.removeClass("dropdown");
		$(".trigger", container).html(button.html());
	})

	$("[data-scroll]").click(function(e){
		e.preventDefault();
		var target = $("#" + $(this).data("scroll"));
		if(target.length){
			$("html, body").stop().animate({
				scrollTop: target.offset().top
			}, 800);
		}
	})

	if($("#cms-gallery").length){
		$( '#cms-gallery' ).sliderPro({
			width: 960,
			height: 600,
			// autoHeight: true,
			fade: true,
			arrows: true,
			buttons: false,
			fullScreen: true,
			// shuffle: true,
			waitForLayers: true,
			thumbnailArrows: true,
			autoplay: false
		});
	}

	if($(".accordion-container").length){
    	$(".accordion-container .trigger a").click(function(e){
    		e.preventDefault();
    		var container = $(this).closest(".accordion-container");
    		container.toggleClass('active');
    		$(".detail", container).slideToggle();
    	})
    }

    $(".search-set .search-btn").click(function(e){
    	if(!$(".search-set input[name='q']").val().trim()){
    		$(".search-set input[name='q']").addClass('highlight').focus();
    		return false;
    	}
    })

	$(document).on('click touchend', function(e){
		if(!$(e.target).closest('.mobile-standard-select-nav').length){
			$(".mobile-standard-select-nav").removeClass("dropdown");
		}
	})
    
	$(window).resize(function(){
		// $("main").css("margin-top", $("header .bottombar").outerHeight() * -1 );
		if($("header").css('position') == 'fixed'){
			$("main").css("padding-top", $("header").outerHeight());
		}else{
			$("main").css("padding-top", 0);
		}
		$("#mobile-menu-overlay").css("padding-top", $("header").outerHeight());
	}).trigger('resize');

	$(window).scroll(function(){
		scrollTop = $(window).scrollTop();
	}).trigger('scroll');
	
})
$(window).load(function() {
	$(".preload").removeClass("preload");
	$(".anim").addClass("ready");
	$(window).trigger('scroll');
})